/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/seo";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Events from "../components/Events";
// @ts-ignore
import workshop from "../images/workshop.jpeg";

export default function Workshops(props: PageProps) {
  return (
    <div className="bg-white">
      <SEO
        title="Workshops"
        description="View upcoming workshops with Erin."
        image={workshop}
      />
      <NavBar {...props} />
      <main>
        <div className="bg-white ">
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            {/* <div className="relative px-4 sm:px-6 lg:px-8 bg-white">
              <div className="max-w-3xl w-full mt-2 leading-8 mb-10">
                <h3 className="px-4 text-3xl font-extrabold tracking-tight text-my-orange sm:text-4xl">
                  Upcoming Workshops
                </h3>
              </div>
              <div className="mt-5 prose text-lg lg:text-xl prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <Events />
              </div>
            </div> */}
            {/* <div className="hidden lg:block bg-my-tan absolute top-0 bottom-0 left-3/4 w-screen" /> */}
            <div className="mx-auto text-base z-10 max-w-prose lg:grid lg:grid-cols-1 lg:gap-8 lg:max-w-none">
              <h3 className="mt-2 text-3xl leading-8 contents lg:hidden font-extrabold tracking-tight text-my-orange sm:text-4xl">
                Upcoming Workshops
              </h3>
            </div>
            <div className="mt-6 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative lg:row-start-1 lg:col-start-2">
                <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                  <figure>
                    <div className="aspect-w-12 aspect-h-12 lg:aspect-none">
                      <img
                        className="rounded-lg shadow-lg object-cover object-top"
                        src={workshop}
                        alt="Picture of Erin in a workshop"
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <div className="mt-8 lg:mt-0">
                <h3 className="mt-8 text-3xl hidden lg:contents font-extrabold tracking-tight text-my-orange sm:text-4xl">
                  Upcoming Workshops
                </h3>
                <div className="mt-5 text-md lg:text-lg prose text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                  <Events />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
